import React from 'react'
import { LivePlayer } from 'ascential-horizon'
import { trackBtnClick } from '../../services/tracking'
import { getUrlByDate } from '../time'
import getThemeConfig from '../../providers/config/themeConfig'

const renderLivePlayer = (content, key, userDetails) => {
  const {
    announcement,
    interactionTool,
    liveEventDate,
    liveStreamUrls,
    slidoSponsorship,
    slidoUrls,
    votemoUrls,
  } = content
  const { actions, isAuthorized, user } = userDetails
  const {
    videoPlatform: {
      featuredVideoOverlay: { button, title, titleAuth, sponsorLogo },
    },
  } = getThemeConfig()
  let interactionToolUrl = ''

  const hasInteractionTool =
    !!interactionTool &&
    ((slidoUrls && slidoUrls.length > 0) ||
      (votemoUrls && votemoUrls.length > 0))

  if (interactionTool === 'Slido') {
    interactionToolUrl = getUrlByDate(liveEventDate, slidoUrls || [])
  }

  if (interactionTool === 'Votemo') {
    interactionToolUrl = getUrlByDate(liveEventDate, votemoUrls || [])
  }

  const overlayButtonUrl = user.loggedIn && !isAuthorized ? button.link : '#'
  const overlayButtonText =
    user.loggedIn && !isAuthorized ? button.textAuth : button.text
  const overlayText = user.loggedIn && !isAuthorized ? titleAuth : title

  function handleOverlayButtonClick(e, label, trackUrl) {
    trackBtnClick(label, trackUrl)

    if (user.loggedIn && !isAuthorized) {
      return
    }

    e.preventDefault()
    if (!user.loggedIn && typeof actions.login === 'function') {
      actions.login()
    }
  }

  function handleToggleClick() {
    return trackBtnClick('Toggle Chat', '#', 'toggle.click')
  }

  const overlayProps = !user.loggedIn
    ? {
        button: {
          handleClick: handleOverlayButtonClick,
          link: overlayButtonUrl,
          text: overlayButtonText,
        },
        title: overlayText,
        sponsorLogo,
      }
    : null

  const livePlayerProps = {
    announcement,
    interactionTool: hasInteractionTool
      ? {
          banner: slidoSponsorship,
          handleToggleClick,
          type: interactionTool || 'Slido',
          url: interactionToolUrl,
          user:
            user && user.loggedIn
              ? {
                  company: user.company,
                  email: user.email,
                  firstName: user.firstName,
                  loggedIn: user.loggedIn,
                }
              : null,
        }
      : null,
    overlay: overlayProps,
    videoUrl: getUrlByDate(liveEventDate, liveStreamUrls),
  }

  return <LivePlayer key={key.toString()} {...livePlayerProps} />
}

export default renderLivePlayer
