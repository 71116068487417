import React from 'react'
import MicroFrontend from '../../components/MicroFrontend'

const renderMicroFrontend = (content, key, userDetails) => {
  const { name, jsSrc, cssSrc } = content

  const microFrontendProps = {
    name: name || '',
    jsSrc: jsSrc || null,
    cssSrc: cssSrc || null,
    config: userDetails,
  }
  if (!microFrontendProps) {
    return null
  }
  return <MicroFrontend key={key.toString()} {...microFrontendProps} />
}

export default renderMicroFrontend
