import React from 'react'
import { ComponentWrapper } from 'ascential-horizon'
import DividerStyles from './Divider.style'

export const Divider = () => (
  <ComponentWrapper columnWidth={[12]}>
    <DividerStyles />
  </ComponentWrapper>
)

export default Divider
