import React from 'react'
import PropTypes from 'prop-types'
import { ComponentWrapper, PageTitle } from 'ascential-horizon'

function Spinner({ message, ...rest }) {
  return (
    <ComponentWrapper columnWidth={12}>
      <PageTitle mb="large" {...rest}>
        {message}
      </PageTitle>
    </ComponentWrapper>
  )
}

Spinner.propTypes = {
  message: PropTypes.string,
}

Spinner.defaultProps = {
  message: 'Loading...',
}

export default Spinner
