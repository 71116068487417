const groupBy = (entities, prop) => {
  const map = new Map(Array.from(entities, (obj) => [obj[prop], []]))
  entities.forEach((obj) => map.get(obj[prop]).push(obj))
  return Array.from(map.values())
}

const groupByCategories = (entities = []) =>
  entities.reduce((res, acc) => {
    // eslint-disable-next-line no-param-reassign
    res[acc.category] = res[acc.category] || []
    res[acc.category].push(acc)
    return res
  }, {})

const groupByTags = (entities = []) => {
  const eventsByTag = {}

  entities.map((event) => {
    const { tags } = event

    if (!tags || tags.length === 0) {
      return event
    }

    tags.map((tag) => {
      // eslint-disable-next-line no-prototype-builtins
      if (!eventsByTag.hasOwnProperty(tag.name)) {
        eventsByTag[tag.name] = []
      }
      return eventsByTag[tag.name].push(event)
    })
    return event
  })
  return eventsByTag
}

const getCategoryNames = (groupedEvents) =>
  groupedEvents.map((carousel) => carousel[0].category)

// Get events with Category
// By default it returns the events as an object, with properties being the categories names
// and values the array of events. It is possible to return them as an array (either sorted on unsorted) using
// options parameter
export const getEventsByCategory = (
  events,
  options = { asArray: false, sorted: false }
) => {
  if (!events || events.length === 0) {
    return []
  }

  const { asArray, sorted } = options

  if (!asArray) {
    return groupByCategories(events)
  }

  const groupedEvents = groupBy(events, 'category')

  if (!sorted) {
    return groupedEvents
  }

  return groupedEvents.sort((a, b) => a[0].categoryOrder - b[0].categoryOrder)
}

// Get events with Tag
export const getEventsByTag = (events) => {
  if (!events || events.length === 0) {
    return []
  }
  return groupByTags(events)
}

export const getEventsByTaxonomy = (
  events,
  taxonomyId,
  options = { asArray: false, sorted: false }
) => {
  if (!events || events.length === 0 || !taxonomyId) {
    return []
  }

  if (taxonomyId === 'category') {
    return getEventsByCategory(events, options)
  }

  if (taxonomyId === 'tag') {
    return getEventsByTag(events)
  }

  return []
}

export const getTaxonomyItems = (groupedEvents, taxonomyId) => {
  if (
    !groupedEvents ||
    !groupedEvents.length ||
    groupedEvents.length === 0 ||
    !taxonomyId
  ) {
    return []
  }

  if (taxonomyId === 'category') {
    return getCategoryNames(groupedEvents)
  }

  // TODO implement for Tags
  if (taxonomyId === 'tag') {
    return []
  }

  return []
}

// Filter out events. Parameter excludeIds, can be either a string with an ID of event that needs to be filtered out
// or an array of IDs that needs to be filtered out
export const filterEvents = (events, excludeIds) => {
  if (!events || events.length === 0) {
    return []
  }

  if (!excludeIds) {
    return events
  }

  if (typeof excludeIds === 'string') {
    return events.filter((event) => event.entryId !== excludeIds)
  }

  if (excludeIds.length > 0) {
    return events.filter((event) => !excludeIds.includes(event.entryId))
  }

  return events
}
