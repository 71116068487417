import React from 'react'
import { List } from 'ascential-horizon'

const renderList = (content, key) => {
  const { title, copy, background, items, instanceName } = content

  const listItems =
    items &&
    items.map((item) => {
      const obj = {
        copy: (item.copy && item.copy.copy) || '',
        date: item.date || '',
      }
      return obj
    })

  const listProps = {
    id: instanceName,
    title: title || '',
    copy: (copy && copy.copy) || '',
    bg: background ? 'secondary' : 'primary',
    items: listItems || null,
  }
  return <List key={key.toString()} {...listProps} />
}

export default renderList
