import moment from 'moment'

// Returns time difference as a date. Assumes input format hhmm
const getTimeDifferenceAsDate = (date = '', startTime = '', endTime = '') => {
  if (
    typeof date === 'undefined' ||
    [date, startTime, endTime].includes('') ||
    [date, startTime, endTime].includes(null)
  ) {
    return false
  }

  const start = new Date().setUTCHours(
    startTime.toString().slice(0, 2),
    startTime.toString().slice(2)
  )
  const end = new Date().setUTCHours(
    endTime.toString().slice(0, 2),
    endTime.toString().slice(2)
  )

  return new Date(end - start)
}

export function getTimeDifference(date, startTime, endTime) {
  const diff = getTimeDifferenceAsDate(date, startTime, endTime)

  if (!diff) {
    return false
  }

  const hours = diff.getUTCHours()
  const minutes = diff.getUTCMinutes()

  if (hours > 5) {
    return 'All-day event'
  }
  if (hours > 0) {
    return minutes > 0 ? `${hours}h ${minutes}min` : `${hours}h`
  }
  return `${minutes}min`
}

export const formatDate = (
  date,
  inputFormat = 'YYYYMMDD',
  outputFormat = 'dddd Do MMMM'
) => {
  if (!date) {
    return ''
  }

  return moment(date, inputFormat).format(outputFormat)
}

export const getDurationText = (mil) => {
  if (parseInt(mil, 10) >= 0) {
    const res = Math.round(mil / 100 / 60) / 10 // get minutes
    let humanTime = ''
    if (res < 2) {
      humanTime = `${Math.round(moment.duration(mil).as('s'))} secs`
    } else if (res > 60) {
      humanTime = `${Math.round(moment.duration(mil).as('h'))} hours`
    } else {
      humanTime = `${Math.round(moment.duration(mil).as('m'))} mins`
    }
    return humanTime
  }
  return null
}

export const getTimeInSeconds = (mil) => {
  const time = parseInt(mil, 10)
  if (time >= 0) {
    return Math.round(mil / 1000)
  }

  return null
}

export const getUrlByDate = (eventDate, urls = [], utcOffset = '+01') => {
  if (!eventDate || !urls || !urls.length || urls.length === 0) {
    return null
  }
  const now = moment().utcOffset(utcOffset)
  let startDate = moment(eventDate)
  let match = 0

  // if it is before the start date, default to first URL.
  if (now.isBefore(startDate)) {
    return urls[match]
  }

  // if it is after the event has finished, default to last URL.
  if (now.isAfter(moment(eventDate).add(urls.length, 'days'))) {
    return urls[urls.length - 1]
  }

  let endDate = moment(eventDate).add(24, 'hours')
  urls.map((url, index) => {
    if (now.isBetween(startDate, endDate)) {
      match = index
    }
    // loops and adds 24 hours so the next item in the list will represent the following day
    startDate = startDate.add(24, 'hours')
    endDate = endDate.add(24, 'hours')
    return url
  })

  return urls[match]
}

export default getTimeDifference
