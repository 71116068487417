import styled from '@emotion/styled'
import { Container } from 'ascential-horizon'

export const OverlayContainer = styled(Container)({
  marginTop: '0 !important',
})

export const BlurContainer = styled('div')(({ theme: { space } }) => ({
  overflow: 'hidden',
  position: 'relative',
  marginTop: '0 !important',
  marginRight: `-${space.base}`,
  marginLeft: `-${space.base}`,
}))

export const BlurredOverlay = styled('div')({
  pointerEvents: 'none',
  overflow: 'hidden',
  userSelect: 'none',
})

export const LoginBox = styled('div')(({ theme: { space, mediaQueries } }) => ({
  textAlign: 'left',
  position: 'absolute',
  left: 0,
  top: 0,
  zIndex: '1',
  padding: `${space.medium} ${space.xlarge}`,

  [mediaQueries.medium]: {
    paddingLeft: '6rem',
    top: '60px',
    maxWidth: '70%',
  },

  [mediaQueries.large]: {
    paddingLeft: '9rem',
  },

  h1: {
    color: 'white',
    margin: `${space.medium} 0`,
    lineHeight: '1',
    fontSize: '25px',
    textTransform: 'uppercase',

    [mediaQueries.medium]: {
      margin: `${space.large} 0 0`,
      fontSize: '62px',
    },
  },

  h3: {
    padding: 0,
    color: 'white',
    margin: `${space.medium} 0 ${space.base}`,
    textTransform: 'uppercase',
    lineHeight: '1',
    fontSize: '15px',

    [mediaQueries.medium]: {
      margin: `0 0 ${space.large}`,
      fontSize: '24px',
    },

    // makes sure brands don't apply any special styling like money2020 and pink slashes
    '&:before': {
      content: 'none',
    },
  },

  img: {
    width: '80px',
    margin: `15px ${space.small}`,

    [mediaQueries.medium]: {
      width: '140px',
      margin: `${space.large} ${space.small}`,
    },
  },
}))

export const BgOverlay = styled('div')({
  content: `''`,
  position: 'absolute',
  top: '0',
  left: '16px',
  right: '0',
  display: 'block',
  width: 'calc(100% - 32px)',
  height: '100%',
  bottom: '0',
  background: 'rgba(4, 4, 4, 0.84)',
  zIndex: '1',
})
