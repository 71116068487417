import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { TextInputType } from 'ascential-horizon'

import {
  StyledBody,
  StyledTextInput,
  SearchContainer,
  InputWrapper,
} from './Search.style'

function Search({ onSearchTermChanged, searchTerm, subtitle, title }) {
  const [searchInput, setSearchInput] = useState(searchTerm)

  useEffect(() => {
    setSearchInput(searchTerm)
  }, [searchTerm])

  const keyPressTimer = useRef(null)

  const handleSearchInputChange = (value) => {
    setSearchInput(value)
    clearTimeout(keyPressTimer.current)
    keyPressTimer.current = setTimeout(() => {
      onSearchTermChanged(value)
    }, 1000)
  }

  return (
    <SearchContainer>
      {title && <h3>{title}</h3>}
      {subtitle && <StyledBody size="xsmall">{subtitle}</StyledBody>}
      <InputWrapper>
        <StyledTextInput
          autocomplete="off"
          placeholder="Search for a topic, company or speaker"
          value={searchInput}
          inputType={TextInputType.Text}
          onChangeCallback={handleSearchInputChange}
        />
      </InputWrapper>
    </SearchContainer>
  )
}

Search.propTypes = {
  onSearchTermChanged: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string,
}

Search.defaultProps = {
  subtitle: '',
  title: '',
}

export default Search
