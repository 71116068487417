import React from 'react'
import { Container, Grid, Column } from 'ascential-horizon'
import { renderTextBlock, renderSectionHeader } from '../../utils/renderers'

const messageInTextBlock = (confirmationMessage) =>
  renderTextBlock(confirmationMessage, 'confirmation-message')

const messageInSectionHeader = (confirmationMessage) => {
  return renderSectionHeader(confirmationMessage, 'confirmation-message', {})
}

const defaultMessage = () => {
  return (
    <Container>
      <Grid justifyContent="center">
        <Column width={[12, null, 10, 8]}>
          <p data-test="conf-message">Thank you. We will be in touch soon</p>
        </Column>
      </Grid>
    </Container>
  )
}

export const messageToUser = (confirmationMessage) => {
  switch (confirmationMessage.type) {
    case 'text':
      return messageInTextBlock(confirmationMessage)
    case 'header':
      return messageInSectionHeader(confirmationMessage)
    default:
      return defaultMessage()
  }
}

export default messageToUser
