import React from 'react'
import PropTypes from 'prop-types'

import CuratedCarousels from './curated'
import TaxonomyCarousels from './taxonomy'

function PortalCarousels({ type, ...rest }) {
  if (type === 'curated') {
    return <CuratedCarousels {...rest} />
  }

  return <TaxonomyCarousels taxonomy={type} {...rest} />
}

PortalCarousels.propTypes = {
  type: PropTypes.string,
}

PortalCarousels.defaultProps = {
  type: 'category',
}

export default PortalCarousels
