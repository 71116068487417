/* eslint-disable no-console */
import axios, { CancelToken } from 'axios'
import getThemeConfig from '../providers/config/themeConfig'
import { parseEvents, parseSpeakers } from './contentPortalParser'

export const apiTimeout = process.env.CONTENT_PORTAL_API_TIMEOUT || 24000

const config = getThemeConfig()

const getValidEvents = (entities = []) => {
  // valid entry being an event which has a category, an ID,
  // and either a video (sessionUrl) or it is Partner or it is an asset
  if (!entities) {
    return []
  }
  return entities.filter(
    (e) =>
      e.eventTypeName &&
      e.entityId &&
      e.imageSrc &&
      (e.sessionUrl ||
        e.eventTypeIsPartner ||
        (e.assetData && e.assetData.length > 0))
  )
}

export const getApiEndpoint = (endpoints, environment) => {
  if (!endpoints || !environment || !endpoints[environment]) {
    return false
  }
  return endpoints[environment]
}

// Get all curated carousels
export const getCuratedCarousels = async () => {
  const {
    videoPlatform: { carouselsUrl },
  } = config
  const url = getApiEndpoint(carouselsUrl, process.env.GATSBY_ENV)
  if (!url) {
    return []
  }
  const source = CancelToken.source()
  setTimeout(() => {
    source.cancel()
  }, apiTimeout)

  const carousels = await axios.get(url, { cancelToken: source.token })
  return carousels && carousels.data && carousels.data.entities
}

// Get all events
export const getEvents = async () => {
  const {
    videoPlatform: { eventsUrl },
  } = config
  const url = getApiEndpoint(eventsUrl, process.env.GATSBY_ENV)
  if (!url) {
    return []
  }
  const source = CancelToken.source()
  setTimeout(() => {
    source.cancel()
  }, apiTimeout)

  const events = await axios.get(url, { cancelToken: source.token })

  const entities = events && events.data && events.data.entities
  console.log('CONTENT PORTAL USING ENDPOINT:', url)
  const validEntities = getValidEvents(entities)
  return parseEvents(validEntities)
}

export const getSpeakers = async () => {
  const {
    videoPlatform: { speakersUrl },
  } = config
  const url = getApiEndpoint(speakersUrl, process.env.GATSBY_ENV)
  if (!url) {
    return []
  }
  const source = CancelToken.source()
  setTimeout(() => {
    source.cancel()
  }, apiTimeout)

  const talents = await axios.get(url, { cancelToken: source.token })
  const entities = talents && talents.data && talents.data.entities
  return parseSpeakers(entities)
}
