import React from 'react'
import { Accordion } from 'ascential-horizon'
import { useHasMounted } from '../hooks'
import { trackBtnClick } from '../../services/tracking'

const renderAccordion = (content, key) => {
  const hasMounted = useHasMounted()
  if (!hasMounted) {
    return null
  }
  const { title, items, ctaLabel, ctaUrl, instanceName } = content

  const handleAccordionChange = (heading) => {
    trackBtnClick(title, '', 'button.click', heading)
  }

  const accordionItems =
    items &&
    items.map((item) => ({
      copy:
        (item.copy &&
          item.copy.childMarkdownRemark &&
          item.copy.childMarkdownRemark.html) ||
        '',
      heading: item.heading || '',
    }))

  const accordionProps = {
    id: instanceName,
    title: title || '',
    content: accordionItems || [],
    cta:
      (ctaLabel &&
        ctaUrl && {
          label: ctaLabel,
          url: ctaUrl,
          onClick: () => trackBtnClick(ctaLabel, ctaUrl),
        }) ||
      '',
    onAccordionChange: handleAccordionChange,
  }

  return <Accordion key={key.toString()} {...accordionProps} />
}

export default renderAccordion
