import styled from '@emotion/styled'
import { TextInput, Body } from 'ascential-horizon'

export const SearchContainer = styled.div(() => ({
  margin: '20px auto',
  position: 'relative',
}))

export const StyledBody = styled(Body)(() => ({
  marginBottom: '20px',
}))

export const StyledTextInput = styled(TextInput)(() => ({
  marginRight: 0,

  '@media (min-width: 576px)': {
    marginRight: '10px',
  },
}))

export const InputWrapper = styled.div(() => ({
  display: 'flex',
  flexWrap: 'wrap',

  '@media (min-width: 576px)': {
    flexWrap: 'nowrap',
  },
}))

export default SearchContainer
