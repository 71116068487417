/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from 'emotion-theming'
import { TextBlock as HorizonTextBlock } from 'ascential-horizon'

const TextBlock = (props) => {
  const { html, heading, featured, banner, instanceName } = props
  const { colors, space } = useTheme()
  const featuredProps = featured && {
    bg: colors.offwhite,
    pt: space.large,
    textAlign: 'center',
  }

  if (banner && banner.image && !banner.image.fluid) {
    banner.image.src = banner.image.file.url
  }

  const textBlockProps = {
    html,
    heading,
    ...featuredProps,
    banner,
    id: instanceName,
  }

  return <HorizonTextBlock {...textBlockProps} />
}

TextBlock.propTypes = {
  html: PropTypes.string.isRequired,
  heading: PropTypes.string,
  featured: PropTypes.bool,
  banner: PropTypes.object,
  instanceName: PropTypes.string,
}

TextBlock.defaultProps = {
  heading: null,
  featured: false,
  banner: null,
  instanceName: null,
}

export default TextBlock
