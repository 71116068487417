import { windowExists } from '../../utils'

export const hideForm = (form) => form.getFormElem().hide()

const matchesCurrentUrl = (followUpUrl) => {
  const currentHref =
    (windowExists && window.location.href.split('?')[0]) || null
  const followUpHref = followUpUrl.split('?')[0]
  return currentHref === followUpHref
}

const redirectTo = (followUpUrl) => {
  if (windowExists) {
    window.location.href = followUpUrl
  }
}

const displayConfirmationText = () => {
  const confirmationText = document.getElementById('confirmationText')
  const formTitle = document.getElementById('marketoFormTitle')
  if (formTitle) {
    formTitle.style.display = 'none'
  }
  if (confirmationText) {
    confirmationText.style.visibility = 'visible'
    confirmationText.style.display = 'block'
  }
  return false
}

const getgacid = () => {
  try {
    // eslint-disable-next-line no-undef
    const tracker = ga.getAll()[0]
    return tracker.get('clientId')
  } catch (e) {
    return 'n/a'
  }
}

export const formLoadHandler = (form) => {
  try {
    form.onSuccess((values, followUpUrl) => {
      hideForm(form)
      if (typeof dataLayer !== 'undefined') {
        // eslint-disable-next-line no-undef
        dataLayer.push({
          event: 'mktoLead',
          gacid: getgacid(),
          mktoFormId: form.getId(),
          ...values,
        })
      }
      return matchesCurrentUrl(followUpUrl)
        ? displayConfirmationText()
        : redirectTo(followUpUrl)
    })

    form.vals({
      gacid: getgacid(),
    })
  } catch (error) {
    console.error('Make sure the form has the correct id')
  }
}

export const resetStyles = (form) => {
  const formEl = form.getFormElem()[0]
  const stylesBase = document.getElementById('mktoForms2BaseStyle')
  const stylesTheme = document.getElementById('mktoForms2ThemeStyle')

  if (stylesTheme) {
    const inlineMarketoStyle = stylesTheme.nextSibling

    if (
      inlineMarketoStyle &&
      inlineMarketoStyle.textContent &&
      inlineMarketoStyle.textContent.indexOf(`mktoForm_`) >= 0
    ) {
      inlineMarketoStyle.outerHTML = ''
    }

    const inFormStyles = formEl.getElementsByTagName('style')
    if (inFormStyles.length) {
      for (let i = 0; i < inFormStyles.length; i += 1) {
        inFormStyles[i].outerHTML = ''
      }
    }

    const styledEls = Array.from(formEl.querySelectorAll('[style]')).concat(
      formEl
    )
    styledEls.forEach((el) => el.removeAttribute('style'))
    stylesTheme.outerHTML = ''
  }
  if (stylesBase) {
    stylesBase.outerHTML = ''
  }
}
