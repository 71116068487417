import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ComponentWrapper } from 'ascential-horizon'
import { resetStyles, formLoadHandler } from './formSubmissionHandlers'
import getThemeConfig from '../../providers/config/themeConfig'
import { messageToUser } from './messageToUser'
import { randomId } from '../../utils'
import { MarketoFormStyled, MarketoWrapper } from './MarketoForm.style'

export const MarketoForm = ({
  confirmationMessage,
  formId,
  title,
  isHidden,
  values,
  submit,
}) => {
  const [activeForm, setActiveForm] = useState(null)
  const { marketo } = getThemeConfig()
  const marketoSrcId = randomId()
  const [marketoConfig] = useState({
    podId: marketo.url,
    munchkinId: marketo.munchkinId,
  })

  const handleForm = () => {
    // eslint-disable-next-line no-undef
    MktoForms2.loadForm(
      marketoConfig.podId,
      marketoConfig.munchkinId,
      formId,
      formLoadHandler
    )
    // eslint-disable-next-line no-undef
    MktoForms2.whenRendered((form) => {
      setActiveForm(form)
      setTimeout(() => resetStyles(form), 0)
    })
  }

  if (values && activeForm) {
    activeForm.setValues(values)
  }

  if (submit && activeForm) {
    activeForm.submit()
  }

  useEffect(() => {
    // if you load multiple forms, the handleForm is executed multiple times
    const script = document.createElement('script')
    script.id = marketoSrcId
    script.onload = handleForm
    script.src = `${marketoConfig.podId}/js/forms2/js/forms2.min.js`
    document.body.appendChild(script)

    return function cleanup() {
      const marketoScript = document.getElementById(marketoSrcId)
      if (marketoScript) {
        marketoScript.outerHTML = ''
      }
    }
  }, [])

  return (
    <MarketoWrapper isHidden={isHidden}>
      <ComponentWrapper>
        {title && <h3 id="marketoFormTitle">{title}</h3>}
        <MarketoFormStyled>
          {/* the div with id "marketoForm" below is used
        to override original marketo form css */}
          <div id="marketoForm">
            <form className="gs-marketo-form" id={`mktoForm_${formId}`} />
          </div>
        </MarketoFormStyled>
      </ComponentWrapper>
      <div
        id="confirmationText"
        style={{ visibility: 'hidden', display: 'none' }}>
        {messageToUser(confirmationMessage)}
      </div>
    </MarketoWrapper>
  )
}

export default MarketoForm

MarketoForm.defaultProps = {
  confirmationMessage: {
    type: '',
  },
  title: '',
  isHidden: false,
  submit: false,
  values: null,
}

MarketoForm.propTypes = {
  confirmationMessage: PropTypes.shape({
    type: PropTypes.string,
  }),
  formId: PropTypes.string.isRequired,
  title: PropTypes.string,
  isHidden: PropTypes.bool,
  submit: PropTypes.bool,
  values: PropTypes.shape(PropTypes.obj),
}
