import { useStaticQuery, graphql } from 'gatsby'

export const useEvents = () => {
  const { allSitePage } = useStaticQuery(
    graphql`
      query eventsQuery {
        allSitePage(filter: { context: { type: { eq: "event" } } }) {
          nodes {
            context {
              alternativeVideoSrc
              category
              categoryDescription
              categoryImage
              categoryIsPartner
              categoryOrder
              categoryPartnerText
              date
              description
              duration
              eventHost
              entryId
              eventOrder
              imageSrc
              metadata {
                categoryPath
                path
                slugTitle
                slugCategory
              }
              sponsorImage
              streamId
              subtitleSrc
              speakers {
                company
                id
                fullName
              }
              title
              videoDuration
              videoLength
              videoSrc
            }
          }
        }
      }
    `
  )
  return (
    allSitePage &&
    allSitePage.nodes &&
    allSitePage.nodes.map((node) => node.context)
  )
}

export default useEvents
