import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Fuse from 'fuse.js'
import { Body, Container, Grid, Column } from 'ascential-horizon'
import { eventToCard } from '../../services/carousel'
import { trackSearch } from '../../services/tracking'
import { useEvents } from '../../hooks/events'
import Carousel from '../Carousel'
import Search from '../Search'

function EventsSearch({ subtitle, title }) {
  const allEvents = useEvents()
  const [events, setEvents] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const options = {
    keys: [
      {
        name: 'title',
        weight: 0.4,
      },
      {
        name: 'description',
        weight: 0.2,
      },
      {
        name: 'eventHost',
        weight: 0.1,
      },
      {
        name: 'category',
        weight: 0.1,
      },
      {
        name: 'speakers.fullName',
        weight: 0.15,
      },
      {
        name: 'speakers.company',
        weight: 0.05,
      },
    ],
    threshold: 0.2,
    tokenize: true,
    matchAllTokens: true,
  }
  const carouselProps = {
    banner: null,
    ctaUrl: '',
    description: '',
    showCta: false,
    display: 'grid',
  }

  const fuse = new Fuse(allEvents, options)

  const handleSearch = (search) => {
    if (!search) {
      setEvents([])
      setSearchTerm(search)
      return
    }

    const visibleEvents = fuse.search(search)
    const cards = visibleEvents.map((event) => eventToCard(event.item))
    setEvents(cards)
    setSearchTerm(search)
    trackSearch(search)
  }

  return (
    <>
      <Container>
        <Grid justifyContent="flex-start">
          <Column width={[12, 12, 8, 8]}>
            <Search
              onSearchTermChanged={handleSearch}
              searchTerm=""
              subtitle={subtitle}
              title={title}
            />
            {events.length === 0 && searchTerm && (
              <Body size="xsmall">
                No results found for a given search term
              </Body>
            )}
          </Column>
        </Grid>
      </Container>
      {events.length > 0 && (
        <Carousel
          {...carouselProps}
          cards={events}
          title={`Found ${events.length} search result${
            events.length > 1 ? 's' : ''
          }`}
        />
      )}
    </>
  )
}

EventsSearch.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string,
}

EventsSearch.defaultProps = {
  subtitle: '',
  title: '',
}

export default EventsSearch
