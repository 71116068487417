const marketoFormDefaults = {
  ctaPaddingIE: null,
  ctaBorderRadius: null,
  textLinkColor: null,
  ctaColorHover: null,
  ctaBgColorHover: 'secondary',
  ctaTexDecorationHover: null,
  ctaBgColor: 'primary',
}

const marketoForm = {
  lionsCreativity: {
    ...marketoFormDefaults,
    ctaPaddingIE: '16px 24px 10px',
    ctaColorHover: 'white',
    ctaBgColorHover: 'primary',
  },
  money2020: {
    ...marketoFormDefaults,
    textLinkColor: 'secondary',
    ctaBorderRadius: '4px !important',
    ctaBgColor: 'secondary',
    ctaColorHover: 'white',
    ctaBgColorHover: 'secondary',
    ctaTexDecorationHover: 'underline !important',
  },
}

export default marketoForm
