import getThemeConfig from '../providers/config/themeConfig'
import {
  getDurationText,
  getTimeDifference,
  getTimeInSeconds,
  formatDate,
} from '../utils/time'
import { generateSlug } from '../utils/slugifier'
import { appendQueryParams } from '../utils/index'

const {
  events: { pathSingular: eventPath },
  speakers: { pathSingular: speakerPath, queryParams },
  videoPlatform: { pagePrefix },
  showInPartnershipText,
} = getThemeConfig()

export const parseEvents = (events = []) => {
  if (!events || events.length === 0) {
    return []
  }

  return events.map((event) => {
    const date = formatDate(event.dateIso)
    // TODO This part needs some more attention with the addition of new taxonomies
    const pathPrefix = pagePrefix ? `/${pagePrefix.slug}` : ''
    const slugCategory = generateSlug(event.eventTypeName) || ''
    const slugTitle = generateSlug(`${event.title}-${event.entityId}`)
    const categoryPath = `${pathPrefix}/category/${slugCategory}`
    const path = `${pathPrefix}/${eventPath}/${slugTitle}`

    // eventTypeIsSponsored and eventTypeIsPartner will never be both true, one or the other
    // we want to build pages for partners, despite not having a sessionURL.

    const speakers =
      event.speakerData && event.speakerData.length > 0
        ? event.speakerData.map((speaker) => {
            const slug = generateSlug(
              `${speaker.speakerFirstName || ''}-${speaker.speakerLastName ||
                ''}-${speaker.entityId}`
            )

            const speakerSlug = pagePrefix
              ? `/${pagePrefix.slug}/${speakerPath}/${slug}`
              : `/${speakerPath}/${slug}`

            return {
              id: speaker.entityId,
              company: speaker.speakerCompany || '',
              firstName: speaker.speakerFirstName || '',
              fullName: `${speaker.speakerFirstName || ''}${
                speaker.speakerFirstName ? ' ' : ''
              }${speaker.speakerLastName}`,
              lastName: speaker.speakerLastName || '',
              slug: speakerSlug,
            }
          })
        : []

    const categoryImage =
      (event.eventTypeIsPartner && event.eventTypePartnerImage) ||
      (event.eventTypeIsSponsored && event.eventTypeSponsorImage) ||
      ''

    const tags =
      event.eventTopics &&
      event.eventTopics.map((tag) => {
        const slug = generateSlug(tag)
        return { name: tag, slug: `/tag/${slug}` }
      })

    const partnershipText = showInPartnershipText ? 'in partnership with' : ''
    let categoryPartnerTextDefault = event.eventTypeIsPartner
      ? partnershipText
      : 'sponsored by'

    if (slugCategory === 'the-creative-crossover') {
      categoryPartnerTextDefault = 'in partnership with'
    }

    return {
      alternativeVideoSrc: event.alternativeVideoUrl || '',
      applicationId: event.applicationId,
      assets: event.assetData || [],
      category: event.eventTypeName,
      categoryDescription: event.eventTypeDescription || '',
      categoryImage,
      categoryIsPartner: event.eventTypeIsPartner || false,
      categoryPartnerText:
        event.eventTypeAdditionalText_Alt || categoryPartnerTextDefault,
      categoryOrder: event.eventTypeImportance || 9999999,
      date,
      description: event.description || '',
      duration: getTimeDifference(date, event.startTime, event.endTime) || '',
      entryId: event.entityId,
      eventHost: event.eventHost,
      eventOrder: event.eventLocationImportance || 99999999,
      imageSrc: event.imageSrc || '',
      metadata: {
        categoryPath,
        path,
        slugCategory,
        slugTitle,
      },
      tags,
      title: event.title || '',
      speakers,
      sponsorImage: event.sponsorImage || '',
      streamId: event.streamId,
      subtitleSrc: event.subtitleUrl || '',
      videoDuration: getDurationText(event.videoLength),
      videoLength: getTimeInSeconds(event.videoLength), // Video length in seconds
      videoSrc: event.sessionUrl || '',
    }
  })
}

const sortByName = (a, b) => {
  if (a.sort.toLowerCase() < b.sort.toLowerCase()) return -1
  if (a.sort.toLowerCase() > b.sort.toLowerCase()) return 1
  return 0
}

export const parseSpeakers = (speakers = []) => {
  if (!speakers || speakers.length === 0) {
    return []
  }

  const parsedSpeakers = speakers.map((speaker) => {
    const name = `${speaker.firstName || ''}${
      speaker.firstName ? ' ' : ''
    }${speaker.lastName || ''}`
    const slug = generateSlug(
      `${speaker.firstName || ''}${
        speaker.firstName ? '-' : ''
      }${speaker.lastName || ''}-${speaker.entityId}`
    )
    const events =
      speaker.eventData && speaker.eventData.length > 0
        ? speaker.eventData
            .filter((event) => event.EVENTTITLE && event.EVENTID) // Remove events with incomplete information
            .sort(
              (a, b) =>
                (a.dateIso || 99999999) - (b.dateIso || 99999999) ||
                a.startTime - b.startTime
            )
            .map((event) => {
              const slugTitle = generateSlug(
                `${event.EVENTTITLE}-${event.EVENTID}`
              )

              const pathPrefix = pagePrefix ? `/${pagePrefix.slug}` : ''
              const url = event.eventTypeName
                ? `${pathPrefix}/${eventPath}/${slugTitle}`
                : false

              return {
                category: event.eventTypeName || '',
                date: formatDate(event.dateIso, 'YYYYMMDD', 'DD/MM/YYYY'),
                endTime: formatDate(event.endTime, 'HHmm', 'HH:mm'),
                hasUrl: event.hasUrl,
                id: event.EVENTID,
                startTime: formatDate(event.startTime, 'HHmm', 'HH:mm'),
                title: event.EVENTTITLE,
                url,
              }
            })
        : []

    return {
      applicationId: speaker.application_id,
      bio: speaker.speakerBiography,
      company: speaker.company,
      events,
      id: speaker.entityId,
      image: appendQueryParams(speaker.imageSrc, queryParams),
      importance: speaker.importance || 1,
      jobTitle: speaker.jobTitle || '',
      name,
      slug,
      subtitle: speaker.lastName || '',
      title: speaker.firstName || '',
      sort: speaker.lastName || speaker.firstName,
    }
  })

  return parsedSpeakers.sort((a, b) => {
    // Sort by importance DESC and then by name alphabetically
    return b.importance - a.importance || sortByName(a, b)
  })
}
