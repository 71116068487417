import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from 'emotion-theming'
import { Cards as HorizonCards } from 'ascential-horizon'
import { trackBtnClick, trackLinkClick } from '../../services/tracking'
import { useWindowWidth } from '../../utils/hooks'

const Cards = (content) => {
  const { breakpoints } = useTheme()
  const windowWidth = useWindowWidth()
  const isMobile = windowWidth < parseInt(breakpoints.large, 10)

  const trackingEvent = {
    name: 'Card CTA',
    event: 'button.click',
  }

  const {
    banner,
    cards,
    cardsPerRow,
    ctaLabel,
    ctaUrl,
    display,
    subtitle,
    title,
    instanceName,
    variant,
    carouselOnMobile,
    itemsToShow,
  } = content

  const cardsProps =
    cards &&
    cards.map((card) => {
      const copy = card.copy && card.copy.copy
      const obj = {
        banner: card.banner || null,
        cta: {
          label: card.ctaLabel || '',
          url: card.ctaUrl || '',
          onClick: () =>
            trackBtnClick(
              trackingEvent.name,
              card.ctaUrl,
              trackingEvent.event,
              card.ctaLabel
            ),
        },
        secondaryCta: {
          label: card.secondaryCtaLabel || '',
          url: card.secondaryCtaUrl || '',
          onClick: () =>
            trackBtnClick(
              trackingEvent.name,
              card.secondaryCtaUrl,
              trackingEvent.event,
              card.secondaryCtaLabel
            ),
        },
        company: card.company || '',
        copy: copy || '',
        image: card.image || null,
        jobTitle: card.jobTitle || '',
        label: card.label || '',
        link: card.url && {
          url: card.url || '',
          onClick: () =>
            trackLinkClick(card.title || card.url || 'image', card.url, 'card'),
        },
        name: card.name || '',
        price: {
          previous: card.previousPrice || '',
          current: card.currentPrice || '',
        },
        title: card.title || '',
        subtitle: card.subtitle || '',
        description: card.description || '',
      }

      if (obj.image && !obj.image.fluid) {
        obj.image.src = obj.image.file.url
      }

      if (obj.banner && obj.banner.image && !obj.banner.image.fluid) {
        obj.banner.image.src = obj.banner.image.file.url
      }

      return obj
    })

  const bannerProps = banner && {
    banner: {
      ...banner,
      mt: '-1.8rem',
      mb: '-1rem',
      size: 'large',
    },
  }

  const cardProps = content &&
    cards && {
      id: instanceName,
      cards: cardsProps,
      cardsPerRow,
      cta: ctaLabel &&
        ctaUrl && {
          label: ctaLabel || '',
          url: ctaUrl || '',
          onClick: () => trackBtnClick(ctaLabel, ctaUrl),
        },
      display: isMobile && carouselOnMobile ? 'carousel' : display,
      title,
      subtitle,
      variant,
      itemsToShow,
      ...bannerProps,
    }

  if (cardProps && banner && banner.image && !banner.image.fluid) {
    cardProps.banner.image.src = banner.image.file.url
  }

  return cardProps ? (
    <HorizonCards {...cardProps} dataTest="horizonCards" />
  ) : null
}

Cards.propTypes = {
  content: PropTypes.oneOfType([PropTypes.object]),
}

Cards.defaultProps = {
  content: {},
}

export default Cards
