import React from 'react'
import TextBlock from '../../components/TextBlock'

const renderTextBlock = (content, key) => {
  const { banner, copy, featured, heading, instanceName } = content
  const html = copy && copy.childMarkdownRemark && copy.childMarkdownRemark.html

  if (banner && banner.image && !banner.image.fluid) {
    banner.image.src = banner.image.file.url
  }

  const textBlockProps = {
    instanceName,
    html,
    heading,
    featured,
    banner,
  }
  return html ? <TextBlock key={key.toString()} {...textBlockProps} /> : null
}

export default renderTextBlock
