import React from 'react'
import PropTypes from 'prop-types'
import sanitizeHtml from 'sanitize-html'
import { TextBlock as HorizonTextBlock } from 'ascential-horizon'

const EmbedIframe = ({ html, pageEmbedIframe }) => {
  const content =
    pageEmbedIframe &&
    pageEmbedIframe.iframeBlock &&
    pageEmbedIframe.iframeBlock.childMarkdownRemark &&
    pageEmbedIframe.iframeBlock.childMarkdownRemark.html

  // Displays a full width iframe
  if (pageEmbedIframe) {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(content, {
            allowedTags: ['iframe'],
            allowedAttributes: false,
          }),
        }}
      />
    )
  }

  return <HorizonTextBlock html={html} />
}
EmbedIframe.propTypes = {
  html: PropTypes.string,
  pageEmbedIframe: PropTypes.oneOfType([PropTypes.object]),
}

EmbedIframe.defaultProps = {
  html: '',
  pageEmbedIframe: null,
}

export default EmbedIframe
