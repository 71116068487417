import React from 'react'
import { Stats } from 'ascential-horizon'
import { trackBtnClick } from '../../services/tracking'

const renderGraphicStats = (content, key) => {
  const {
    title,
    subtitle,
    itemsPerRow,
    items,
    ctaLabel,
    ctaUrl,
    instanceName,
  } = content
  const perRow = itemsPerRow || 4
  const buttonProps =
    ctaLabel && ctaUrl
      ? {
          button: {
            label: ctaLabel || '',
            url: ctaUrl || '',
            onClick: () => trackBtnClick(ctaLabel, ctaUrl),
          },
        }
      : null

  const statsItems =
    items &&
    // makes sure we only show stats less that itemsPerRow to keep a clean design
    items
      .filter((_, idx) => idx < perRow)
      .map(({ title: t, subtitle: s }) => ({
        itemTitle: t || '',
        itemSubtitle: s || '',
      }))

  const statsProps = {
    id: instanceName,
    title: title || '',
    subtitle: subtitle || '',
    itemsPerRow: perRow,
    list: statsItems || null,
    ...buttonProps,
  }

  return <Stats key={key.toString()} {...statsProps} />
}

export default renderGraphicStats
