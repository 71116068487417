import React from 'react'
import { ImageGrid } from 'ascential-horizon'

const renderImageGrid = (content, key) => {
  const { imageLinks, imageSize, imagesPerRow, title, instanceName } = content
  const size = imageSize === 'normal' ? 'medium' : imageSize

  const imagesProps =
    imageLinks &&
    imageLinks.map(({ url, image }) => {
      // we pass the url as alt so we can satisfy GA requirements and the way images are being reported.
      // it is not something we want to extend.
      // TODO there is no custom GA for this yet, it is not defined
      const obj = { url, alt: url, ...image }
      if (obj && !obj.fluid) {
        obj.src = image?.file?.url
      }

      return obj
    })

  const gridProps = {
    id: instanceName,
    list: imagesProps || [],
    imagesPerRow,
    size,
    title,
  }

  return <ImageGrid key={key.toString()} {...gridProps} />
}

export default renderImageGrid
