import React from 'react'
import PropTypes from 'prop-types'
import { trackBtnClick } from '../../services/tracking'
import HorizonAnnouncement from './Announcement.style'

function Announcement({
  ctaLabel,
  ctaUrl,
  title,
  variant,
  description,
  instanceName,
}) {
  const sub =
    description &&
    description.childMarkdownRemark &&
    description.childMarkdownRemark.html

  const buttonProps =
    ctaLabel && ctaUrl
      ? {
          button: {
            label: ctaLabel || '',
            url: ctaUrl || '',
            onClick: () => trackBtnClick(ctaLabel, ctaUrl),
          },
        }
      : null

  const announcementProps = {
    id: instanceName,
    subtitle: sub,
    title,
    variant,
    ...buttonProps,
  }

  return (
    announcementProps && (
      <HorizonAnnouncement
        columnWidth={[11, null, 10]}
        data-test="announcement"
        {...announcementProps}
      />
    )
  )
}

Announcement.propTypes = {
  instanceName: PropTypes.string,
  description: PropTypes.shape(Object),
  ctaLabel: PropTypes.string,
  ctaUrl: PropTypes.string,
  title: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
}

Announcement.defaultProps = {
  instanceName: '',
  description: null,
  ctaLabel: '',
  ctaUrl: '',
  title: '',
  variant: 'primary',
}

export default Announcement
