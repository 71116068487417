import slugify from 'slugify'

export const generateSlug = (
  message,
  options = { lower: true, strict: true }
) => {
  if (!message) {
    return ''
  }

  return slugify(message, options)
}

export const slugifyList = (list = []) => {
  if (!list.length && list.length === 0) {
    return false
  }

  return list.map((message) => generateSlug(message))
}

export default generateSlug
