import { appendQueryParams, stripHtml } from '../utils'
import getThemeConfig from '../providers/config/themeConfig'

const { carouselTextLimit } = getThemeConfig()
const defaultBannerProps = {
  mt: '-1.8rem',
  mb: '-1rem',
  size: 'large',
}

export const getBannerProps = (
  imageUrl,
  label,
  options = defaultBannerProps
) => {
  if (!imageUrl) {
    return null
  }

  return {
    label,
    image: {
      file: {
        url: imageUrl,
      },
    },
    ...options,
  }
}

export const eventToCard = ({
  categoryDescription,
  categoryImage,
  categoryPartnerText,
  eventOrder,
  videoDuration,
  imageSrc,
  metadata,
  sponsorImage,
  description,
}) => {
  const img = appendQueryParams(imageSrc, { resize: '550,0', quality: 60 })
  const strippedCopy = stripHtml(description)
  const trimmedCopy = `${strippedCopy.substring(0, carouselTextLimit)}${
    strippedCopy.length >= carouselTextLimit ? '...' : ''
  }`

  const banner = getBannerProps(sponsorImage, 'Sponsored by', {})
  const categoryBanner = getBannerProps(categoryImage, categoryPartnerText)
  return {
    copy: {
      copy: trimmedCopy,
    },
    label: videoDuration,
    banner,
    categoryBanner,
    categoryDescription,
    categoryUrl: metadata.categoryPath,
    eventOrder,
    image: imageSrc && {
      file: {
        url: img,
      },
    },
    url: metadata.path,
  }
}

export default eventToCard
