import React from 'react'
import PropTypes from 'prop-types'
import { Stack } from 'ascential-horizon'
import Cards from '../Cards'
import { Divider as CarouselDivider } from '../Divider'

function Carousel({
  banner,
  cards,
  ctaUrl,
  description,
  display,
  title,
  showCta,
}) {
  const cardProps = cards.length > 0 && {
    banner,
    cards,
    cardsPerRow: 3,
    ctaLabel: 'View all',
    ctaUrl: showCta ? ctaUrl : null,
    display,
    subtitle: description,
    title,
    variant: 'article',
  }

  return (
    cardProps && (
      <Stack space="large">
        <Cards {...cardProps} />
        <CarouselDivider />
      </Stack>
    )
  )
}

Carousel.propTypes = {
  banner: PropTypes.shape({
    label: PropTypes.string,
  }),
  cards: PropTypes.arrayOf(PropTypes.object),
  ctaUrl: PropTypes.string,
  description: PropTypes.string,
  display: PropTypes.string,
  showCta: PropTypes.bool,
  title: PropTypes.string,
}

Carousel.defaultProps = {
  banner: null,
  cards: [],
  ctaUrl: '',
  description: '',
  display: 'carousel',
  showCta: true,
  title: '',
}

export default Carousel
