import React from 'react'
import { MediaBlock } from 'ascential-horizon'

const columnWidthMapping = {
  default: [12, null, 10, 8],
  large: [12, null, 10],
  xlarge: 12,
}

const renderImageBlock = (content, key) => {
  const {
    videoAspectRatio,
    videoUrl,
    image,
    caption,
    size,
    title,
    subtitle,
    ...rest
  } = content

  const width = columnWidthMapping[size] || columnWidthMapping.default
  const placeholderImage = 'https://via.placeholder.com/750x420.png?text=16:9'

  const mediaProps = videoUrl
    ? {
        video: {
          url: videoUrl,
          aspectRatio: videoAspectRatio || '16:9',
          config: {
            file: { attributes: { poster: image ? image.file.url : null } },
          },
        },
      }
    : {
        image: image || { src: placeholderImage },
      }

  if (image && !image.fluid) {
    image.src = image.file.url
  }

  const imageBlockProps = {
    caption,
    title,
    subtitle,
    ...mediaProps,
  }

  return imageBlockProps ? (
    <MediaBlock
      key={key.toString()}
      {...imageBlockProps}
      columnWidth={width}
      {...rest}
    />
  ) : null
}

export default renderImageBlock
