import React from 'react'
import PortalCarousels from '../../components/PortalCarousels'

const renderPortalCarousels = (content, key) => {
  const { display, showOnly, type } = content

  const portalCarouselsProps = {
    display: display || 'carousel',
    showOnly: showOnly || [],
    type: type || 'category',
  }

  return <PortalCarousels key={key.toString()} {...portalCarouselsProps} />
}

export default renderPortalCarousels
