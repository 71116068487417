import React from 'react'
import {
  renderCountdown,
  renderAccordion,
  renderEmbedIframe,
  renderFeaturePanel,
  renderImageBlock,
  renderImageGrid,
  renderList,
  renderLivePlayer,
  renderMarketoForm,
  renderMicroFrontend,
  renderPortalCarousels,
  renderQuote,
  renderSectionHeader,
  renderTextBlock,
  renderGraphicStats,
  renderTabs,
} from './renderers'
import FeaturedVideoComp from '../components/FeaturedVideo'
import Cards from '../components/Cards'
import Announcement from '../components/Announcement'
import Billboard from '../components/Billboard'
import EventsSearch from '../components/EventsSearch'

const renderComponent = (content, key, userDetails) => {
  switch (content.__typename) {
    case 'ContentfulCards': {
      return <Cards key={key.toString()} {...content} />
    }

    case 'ContentfulCountdown': {
      return renderCountdown(content, key)
    }

    case 'ContentfulFeaturePanel': {
      return renderFeaturePanel(content, key, userDetails)
    }

    case 'ContentfulAnnouncement': {
      return (
        <Announcement
          key={key.toString()}
          displayName="Announcement"
          {...content}
        />
      )
    }

    case 'ContentfulBillboard': {
      return (
        <Billboard key={key.toString()} displayName="Billboard" {...content} />
      )
    }

    case 'ContentfulImageBlock': {
      return renderImageBlock(content, key)
    }

    case 'ContentfulList': {
      return renderList(content, key)
    }

    case 'ContentfulMarketoForm': {
      return renderMarketoForm(content, key)
    }

    case 'ContentfulMicroFrontend': {
      return renderMicroFrontend(content, key, userDetails)
    }

    case 'ContentfulQuote': {
      return renderQuote(content, key)
    }

    case 'ContentfulSectionHeader': {
      return renderSectionHeader(content, key, userDetails)
    }

    case 'ContentfulTextBlock': {
      return renderTextBlock(content, key)
    }

    case 'ContentfulEmbedIframe': {
      return renderEmbedIframe(content, key)
    }

    case 'ContentfulImageGrid': {
      return renderImageGrid(content, key)
    }

    case 'ContentfulGraphicStats': {
      return renderGraphicStats(content, key)
    }

    case 'ContentfulLivePlayer': {
      return renderLivePlayer(content, key, userDetails)
    }

    case 'ContentfulAllPortalCarousels': {
      return renderPortalCarousels(content, key)
    }

    case 'ContentfulSearch': {
      return <EventsSearch key={key.toString()} {...content} {...userDetails} />
    }

    case 'ContentfulFeaturedVideo': {
      return (
        <FeaturedVideoComp key={key.toString()} {...content} {...userDetails} />
      )
    }

    case 'ContentfulAccordion': {
      return renderAccordion(content, key)
    }

    case 'ContentfulTabs': {
      return renderTabs(content, key)
    }

    default:
      return null
  }
}

const renderAllContent = (content, userDetails = {}) => {
  return (
    !!content &&
    content.map((contentProps, key) =>
      renderComponent(contentProps, key, userDetails)
    )
  )
}

export default renderAllContent
