import React from 'react'
import PropTypes from 'prop-types'
import { useEvents } from '../../hooks/events'
import { eventToCard } from '../../services/carousel'
import {
  getEventsByTaxonomy,
  getTaxonomyItems,
  filterEvents,
} from '../../services/taxonomy'
import { sortByObjectProperty } from '../../utils'
import { generateSlug, slugifyList } from '../../utils/slugifier'
import Carousel from '../Carousel'

function TaxonomyCarousels({ excludeIds, showOnly, taxonomy, ...rest }) {
  const allEvents = useEvents()
  const groupedEvents = getEventsByTaxonomy(allEvents, taxonomy, {
    asArray: true,
    sorted: true,
  })
  const taxonomyItems = getTaxonomyItems(groupedEvents, taxonomy)
  let carousels = [...taxonomyItems]

  // Filter out carousels based on showOnly field
  if (showOnly.length > 0) {
    const showOnlySlugs = slugifyList(showOnly)
    carousels = taxonomyItems.filter((carousel) => {
      return showOnlySlugs.includes(generateSlug(carousel))
    })
  }

  if (carousels.length === 0) {
    return null
  }

  return (
    <>
      {carousels.map((carousel, idx) => {
        let events = groupedEvents[taxonomyItems.indexOf(carousel)]
        if (excludeIds) {
          events = filterEvents(events, excludeIds)
        }
        if (events.length === 0) {
          return null
        }
        // Sort by eventOrder field Ascending, ex. 1 - first on the list
        const sortedEvents = sortByObjectProperty(events, 'eventOrder')
        const cards = sortedEvents.map((event) => eventToCard(event))
        // TODO ctaUrl needs to be looked at properly when adding Tags
        const ctaUrl = cards[0].categoryUrl

        const carouselProps = {
          banner: cards[0].categoryBanner,
          cards,
          ctaUrl,
          description: cards[0].categoryDescription,
          showCta: true,
          title: carousel,
          ...rest,
        }

        // eslint-disable-next-line react/no-array-index-key
        return <Carousel key={`${idx}-carousel`} {...carouselProps} />
      })}
    </>
  )
}

TaxonomyCarousels.propTypes = {
  excludeIds: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  showOnly: PropTypes.arrayOf(PropTypes.string),
  taxonomy: PropTypes.string,
}

TaxonomyCarousels.defaultProps = {
  excludeIds: '',
  showOnly: [],
  taxonomy: 'category',
}

export default TaxonomyCarousels
