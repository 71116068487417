import React from 'react'
import { MarketoForm as MarketoFormComponent } from '../../components/MarketoForm'

const renderMarketoForm = (content, key) => {
  const { title, formId, confirmationMessage } = content
  let confirmationProps = {}

  if (
    confirmationMessage &&
    confirmationMessage.__typename === 'ContentfulTextBlock'
  ) {
    confirmationProps = {
      ...confirmationMessage,
      type: 'text',
    }
  }

  if (
    confirmationMessage &&
    confirmationMessage.__typename === 'ContentfulSectionHeader'
  ) {
    confirmationProps = {
      ...confirmationMessage,
      type: 'header',
    }
  }
  const marketoFormProps = {
    title: title || '',
    formId: formId || '',
    confirmationMessage: confirmationProps || null,
  }
  if (!marketoFormProps) {
    return null
  }
  return <MarketoFormComponent key={key.toString()} {...marketoFormProps} />
}

export default renderMarketoForm
