import React from 'react'
import { Quote } from 'ascential-horizon'

const renderQuote = (content, key) => {
  const {
    copy,
    name,
    jobTitle,
    company,
    image,
    variant,
    instanceName,
  } = content
  const variation = variant === 'yes' ? 'primary' : 'default'

  if (image && !image.fluid) {
    image.src = image.file.url
  }

  const quoteProps = {
    id: instanceName,
    copy: (copy && copy.copy) || '',
    name: name || '',
    jobTitle: jobTitle || '',
    company: company || '',
    image: image || null,
    variant: variation,
    displayName: 'Quote',
  }
  if (!quoteProps) {
    return null
  }
  return <Quote key={key.toString()} {...quoteProps} />
}

export default renderQuote
