import React from 'react'
import PropTypes from 'prop-types'
import { MediaBlock, ComponentWrapper } from 'ascential-horizon'
import getThemeConfig from '../../providers/config/themeConfig'
import LoginOverlay from '../LoginOverlay'
import Announcement from '../Announcement'
import { trackVideo } from '../../services/tracking'

function FeaturedVideo({
  location,
  videoUrl,
  ctaLabel,
  ctaUrl,
  title,
  label,
  description,
}) {
  const {
    featuredVideo: { overlay },
  } = getThemeConfig()
  const videoProps = videoUrl && {
    video: {
      url: videoUrl,
      aspectRatio: '16:9',
      playing: true,
      muted: true,
      loop: true,
      stopOutOfViewport: false,
      progressInterval: 1000 * 10, // check progress every  10 sec
      onPause: () => trackVideo(title, 'pause', 'Featured Video', null, 0),
      onProgress: (e) => {
        if (e && e.played !== 0) {
          trackVideo(title, e.played, 'Featured Video', null, 0)
        }
      },
      onStart: () => trackVideo(title, 0, 'Featured Video', null, 0),
      config: {
        file: {
          attributes: {
            controlsList: 'nodownload',
            onContextMenu: (e) => e.preventDefault(),
          },
        },
      },
    },
  }

  const overlayProps = {
    location,
    ...overlay,
  }
  const Title = (
    <>
      <p style={{ paddingBottom: '1rem' }}>{label}</p>
      {title}
    </>
  )

  const announcementProps = {
    ctaLabel,
    ctaUrl,
    title: Title,
    subtitle: description,
  }
  return (
    <div>
      {videoUrl && (
        <LoginOverlay {...overlayProps}>
          <MediaBlock {...videoProps} columnWidth={12} />
        </LoginOverlay>
      )}
      {title && (
        <ComponentWrapper columnWidth={[12]}>
          <Announcement {...announcementProps} />
        </ComponentWrapper>
      )}
    </div>
  )
}

FeaturedVideo.propTypes = {
  label: PropTypes.string,
  videoUrl: PropTypes.string,
  ctaLabel: PropTypes.string,
  ctaUrl: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.shape({
    childMarkdownRemark: PropTypes.shape(Object),
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
}

FeaturedVideo.defaultProps = {
  label: 'Now showing:',
  videoUrl: null,
  ctaLabel: null,
  ctaUrl: null,
  title: null,
  description: null,
}

export default FeaturedVideo
