import styled from '@emotion/styled'
import { Announcement } from 'ascential-horizon'

const HorizonAnnouncement = styled(Announcement)(
  ({ theme: { mediaQueries } }) => ({
    paddingTop: '1rem',
    paddingBottom: '1rem',

    [mediaQueries.medium]: {
      paddingTop: '3rem',
      paddingBottom: '3rem',
    },
  })
)

export default HorizonAnnouncement
