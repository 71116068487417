import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'ascential-horizon'
import {
  BlurredOverlay,
  BlurContainer,
  LoginBox,
  BgOverlay,
  OverlayContainer,
} from './LoginOverlay.style'
import { useIdentity } from '../../providers/identityContext'
import { useHasMounted } from '../../utils/hooks'

function LoginOverlay({
  children,
  title,
  subtitle,
  buttonText,
  buttonVariant,
  sponsorLogo,
}) {
  const { isAuthorized, login } = useIdentity()

  const hasMounted = useHasMounted()
  if (!hasMounted) {
    return null
  }
  const handleLoginClick = (e) => {
    e.preventDefault()
    login()
  }

  return isAuthorized ? (
    children
  ) : (
    <OverlayContainer>
      <BlurContainer className="BlurContainer">
        <BgOverlay />
        <LoginBox className="LoginBox">
          <h1>{title}</h1>
          <h3>{subtitle}</h3>
          <Button variant={buttonVariant} onClick={handleLoginClick}>
            {buttonText}
          </Button>
          {sponsorLogo && <img src={sponsorLogo} alt="Sponsor Logo" />}
        </LoginBox>
        <BlurredOverlay className="BlurredOverlay">{children}</BlurredOverlay>
      </BlurContainer>
    </OverlayContainer>
  )
}

LoginOverlay.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonText: PropTypes.string,
  buttonVariant: PropTypes.string,
  sponsorLogo: PropTypes.string,
}

LoginOverlay.defaultProps = {
  title: 'Create a free account',
  subtitle: 'or log in with your existing Cannes Lions account details',
  buttonText: 'Login/Register',
  buttonVariant: 'secondary',
  sponsorLogo: '',
}

export default LoginOverlay
