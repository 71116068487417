import React from 'react'
import EmbedIframe from '../../components/EmbedIframe'

const renderEmbedIframe = (content, key) => {
  const html =
    content.iframeBlock &&
    content.iframeBlock.childMarkdownRemark &&
    content.iframeBlock.childMarkdownRemark.html

  return <EmbedIframe key={key.toString()} html={html} />
}

export default renderEmbedIframe
