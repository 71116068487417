import React from 'react'
import { FeaturePanel } from 'ascential-horizon'
import { trackAddToCalendarClick, trackBtnClick } from '../../services/tracking'

const renderFeaturePanel = (content, key, userDetails) => {
  const {
    copy,
    ctaBehindLogin,
    ctas,
    ctaLabel,
    ctaUrl,
    currentPrice,
    image,
    instanceName,
    previousPrice,
    textPosition,
    title,
    variant,
    videoUrl,
  } = content
  const { actions, isAuthorized, user } = userDetails

  function getCalendarClickEvent(featurePanelTitle) {
    return (event, onToggle) => {
      if (ctaBehindLogin && !isAuthorized) {
        return false
      }

      if (onToggle && typeof onToggle === 'function') {
        onToggle()
      }

      return trackAddToCalendarClick('Add to Calendar', featurePanelTitle)
    }
  }

  function handleClick(e, label, url) {
    const loggedIn = !!user.loggedIn
    if (ctaBehindLogin && !isAuthorized) {
      e.preventDefault()
    }

    if (ctaBehindLogin && !loggedIn) {
      return actions.login()
    }

    return trackBtnClick(label, url)
  }

  const imageProps =
    image && !image.fluid ? { ...image, src: image.file.url } : image

  const mediaProps = videoUrl
    ? {
        video: {
          url: videoUrl,
          aspectRatio: '16:9',
        },
      }
    : {
        image: imageProps || { src: '/' },
      }

  const featurePanelProps = {
    id: instanceName,
    title: title || '',
    copy: copy?.childMarkdownRemark?.html || '',
    variant: variant || 'primary',
    textPosition: textPosition || 'right',
    cta:
      (ctaLabel &&
        ctaUrl && {
          label: ctaLabel || '',
          url: ctaUrl || '',
          onClick: (e) => handleClick(e, ctaLabel, ctaUrl),
        }) ||
      '',
    price: previousPrice &&
      currentPrice && {
        previous: previousPrice || '',
        current: currentPrice || '',
      },
    ...mediaProps,
  }

  if (ctas) {
    const ctaReference = ctas.map((cta) => {
      if (cta?.__typename === 'ContentfulCtaAddToCalendar') {
        return {
          key: cta?.title || '',
          title: cta?.title,
          description: cta?.description?.childMarkdownRemark?.html || '',
          startTime: cta?.startTime,
          endTime: cta?.endTime,
          location: cta?.location || '',
          buttonLabel: 'Add to Calendar',
          showIcon: true,
          variant: 'secondary',
          onClick: getCalendarClickEvent(cta?.title),
          disabled: ctaBehindLogin && !isAuthorized,
        }
      }
      return {
        key: cta?.label,
        label: cta?.label,
        url: cta?.url,
        disabled: user.loggedIn && !isAuthorized,
        onClick: (e) => handleClick(e, cta?.label, cta?.url),
      }
    })

    featurePanelProps.ctas = ctaReference
  }

  return <FeaturePanel key={key.toString()} {...featurePanelProps} />
}

export default renderFeaturePanel
