import React from 'react'
import Tabs from '../../components/Tabs'
import { trackTabClick } from '../../services/tracking'

const renderTabs = (content, key) => {
  const { title, tabs } = content

  const handleTabChange = (name) => {
    trackTabClick(name)
  }

  const tabProps = {
    onTabChange: handleTabChange,
    title,
    tabs,
  }

  return tabs && <Tabs key={key?.toString()} {...tabProps} />
}

export default renderTabs
