import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Tabs as HorizonTabs, Spacer } from 'ascential-horizon'
import renderAllContent from '../../utils/componentRenderer'

function Tabs({ onTabChange, tabs }) {
  const [selectedIndex, setSelectedTab] = useState(0)

  return (
    <>
      <HorizonTabs
        defaultSelectedIndex={selectedIndex}
        onIndexChange={setSelectedTab}
        onTabChange={onTabChange}
        tabs={tabs}
      />
      <Spacer>{renderAllContent(tabs[selectedIndex]?.content)}</Spacer>
    </>
  )
}

Tabs.propTypes = {
  onTabChange: PropTypes.func,
  tabs: PropTypes.arrayOf(PropTypes.any),
}

Tabs.defaultProps = {
  onTabChange: () => {},
  tabs: [],
}

export default Tabs
