import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useEvents } from '../../hooks/events'
import { eventToCard, getBannerProps } from '../../services/carousel'
import { getCuratedCarousels } from '../../services/contentPortal'
import { generateSlug, slugifyList } from '../../utils/slugifier'
import Carousel from '../Carousel'
import Spinner from './spinner'

function CuratedCarousels({ showOnly, ...rest }) {
  const [carousels, setCarousels] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const allEvents = useEvents()
  useEffect(() => {
    const fetchCuratedCarousels = async () => {
      try {
        let items = await getCuratedCarousels()
        // Filter out carousels based on showOnly field
        if (showOnly.length > 0) {
          const showOnlySlugs = slugifyList(showOnly)
          items = items.filter((carousel) => {
            return showOnlySlugs.includes(generateSlug(carousel.carouselName))
          })
        }
        setLoading(false)
        setCarousels(items)
      } catch (e) {
        setLoading(false)
        setError(true)
      }
    }

    fetchCuratedCarousels()
  }, [])

  // Error while fetching carousels - don't render anything
  if (error) {
    console.error(`Request to fetch curated carousels has failed`)
    return null
  }

  // Fetching carousels from the API - show loading indication
  if (loading) {
    return <Spinner />
  }

  // Error while fetching carousels or no carousels found - don't render anything
  if (carousels.length === 0) {
    return null
  }

  return (
    <>
      {carousels.map((carousel) => {
        const {
          carouselAdditionalText,
          carouselDescription,
          carouselName,
          carouselPartnerImage,
          carouselSponsorImage,
          eventData,
        } = carousel

        const events = eventData
          .map((id) => allEvents.find((item) => item.entryId === id))
          .filter((item) => item && item.entryId)

        const eventsNotFound = eventData.length - events.length

        // Checking if all events have been found
        if (eventsNotFound !== 0) {
          console.warn(
            `${eventsNotFound} ${
              eventsNotFound === 1 ? 'event' : 'events'
            } not found in the directory`
          )
        }

        // No events found in the carousel, don't render anything
        if (events.length === 0) {
          return null
        }

        const cards = events.map((event) => eventToCard(event))
        const banner = getBannerProps(
          carouselSponsorImage || carouselPartnerImage,
          carouselAdditionalText
        )
        const carouselProps = {
          banner,
          cards,
          description: carouselDescription,
          showCta: false,
          title: carouselName,
          ...rest,
        }

        return <Carousel key={carouselName} {...carouselProps} />
      })}
    </>
  )
}

CuratedCarousels.propTypes = {
  showOnly: PropTypes.arrayOf(PropTypes.string),
}

CuratedCarousels.defaultProps = {
  showOnly: [],
}

export default CuratedCarousels
