import React from 'react'
import { Countdown } from 'ascential-horizon'

const renderCountdown = (content, key) => {
  const { title, variant, date, ctaUrl, ctaLabel, instanceName } = content

  const countdownProps = {
    id: instanceName,
    title,
    variant: variant ? variant.toLowerCase() : 'regular',
    date,
    cta: {
      url: ctaUrl,
      label: ctaLabel,
    },
  }
  if (!countdownProps) {
    return null
  }
  return (
    <Countdown
      key={key.toString()}
      displayName="Countdown"
      {...countdownProps}
    />
  )
}

export default renderCountdown
