import styled from '@emotion/styled'
import marketoForm from './MarketoForm.themes'

export const MarketoWrapper = styled('div')(({ isHidden }) => ({
  display: isHidden ? 'none' : 'initial',
}))

export const MarketoFormStyled = styled('div')(
  ({ theme: { colors, space, slug, mediaQueries } }) => ({
    '#marketoForm .gs-marketo-form': {
      width: '100% !important',

      'input.mktoField, textarea, select': {
        borderRadius: '0!important',
        border: `1px solid ${colors.greydark}`,
        padding: space.base,
        width: '100%!important',
        resize: 'vertical',
        color: 'inherit!important',
        fontFamily: 'inherit!important',
        fontSize: 'inherit',
        height: 'auto!important',
        lineHeight: 'inherit!important',
        boxShadow: 'none!important',

        '&:focus': {
          outline: 'none',
          boxShadow: `0 0 4px 0 ${colors.primary} !important`,
          borderColor: `${colors.primary} !important`,
        },
      },

      '.mktoCheckboxList': {
        width: '100% !important',

        'input[type=checkbox]': {
          display: 'inline-block',
          width: '12px !important',
          verticalAlign: 'top',
          cursor: 'pointer',

          [mediaQueries.small]: {
            width: '13px !important',
          },
        },
        label: {
          cursor: 'pointer',
          display: 'inline-block',
          width: '93% !important',

          [mediaQueries.small]: {
            width: '95% !important',
          },
          [mediaQueries.large]: {
            width: '96% !important',
          },
        },
      },

      select: {
        border: `1px solid ${colors.greydark}`,
        padding: `${space.base} ${space.medium} ${space.base} ${space.base}`,
        width: '100%',
        boxShadow: 'none',
        backgroundColor: colors.white,
        backgroundImage: 'none',
        appearance: 'none',
      },

      '.mktoFieldWrap': {
        marginBottom: `${space.large}!important`,

        'select + .mktoClear': {
          position: 'relative',
        },
      },

      '.mktoError': {
        fontSize: '1.4rem',
        lineHeight: 'inherit',
        marginTop: space.xsmall,
        color: `${colors.error} !important`,
        fontFamily: 'inherit !important',

        '.mktoErrorArrowWrap, .mktoErrorMsg': {
          color: `${colors.error} !important`,
          lineHeight: 'inherit !important',
          fontFamily: 'inherit !important',
          float: 'none!important',
        },
      },

      '.mktoRequiredField .mktoAsterix': {
        display: 'block',
      },

      '.mktoAsterix': {
        color: `${colors.error} !important`,
        display: 'none',
        float: 'left',
        paddingLeft: 0,
        paddingRight: space.xsmall,
      },

      '.mktoLabel': {
        width: 'auto!important',
        display: 'inline-block!important',
        letterSpacing: '.5px',
        marginBottom: space.xsmall,
        paddingBottom: '0!important',
        fontSize: 'inherit !important',
        lineHeight: 'inherit !important',
        fontWeight: 'normal!important',
        color: `${colors.primary} !important`,
        fontFamily: 'inherit !important',
      },

      '.mktoButtonRow': {
        '.mktoButtonWrap': {
          marginLeft: '0 !important',
        },
      },

      '.mktoButton': {
        display: 'inline-block',
        verticalAlign: 'middle',
        font: 'inherit',
        textalign: 'center',
        margin: 0,
        marginTop: space.medium,
        cursor: 'pointer',
        transition: 'all .3s ease-in-out',
        border: 'none',
        fontSize: 'inherit !important',
        color: colors.white,
        textDecoration: 'none',
        padding: `${space.medium} ${space.large}`,
        fontWeight: 'bold',
        backgroundImage: 'none !important',
        backgroundColor: marketoForm[slug]
          ? `${colors[marketoForm[slug].ctaBgColor]} !important`
          : `${colors.primary} !important`,
        width: '100%',
        height: 'auto!important',
        lineHeight: 'inherit !important',
        borderRadius: marketoForm[slug]
          ? marketoForm[slug].ctaBorderRadius
          : '0 !important',

        '@media screen and (min-width: 768px)': {
          width: 'auto',
          textAlign: 'center',
        },

        '&:hover': {
          background: marketoForm[slug]
            ? `${colors[marketoForm[slug].ctaBgColorHover]} !important`
            : `${colors.secondary} !important`,
          color: marketoForm[slug]
            ? `${colors[marketoForm[slug].ctaColorHover]} !important`
            : `${colors.black} !important`,
          textDecoration: marketoForm[slug]
            ? marketoForm[slug].ctaTexDecorationHover
            : 'none !important',
          textDecorationThickness: '2px !important',
          textUnderlineOffset: '5px !important',
          transform: 'none!important',
        },

        '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
          padding: marketoForm[slug]
            ? marketoForm[slug].ctaPaddingIE
            : 'initial',
        },
      },

      '.mktoHtmlText': {
        width: '100% !important',

        a: {
          color: marketoForm[slug]
            ? colors[marketoForm[slug].textLinkColor]
            : 'inherit',
        },
      },
    },

    // Custom styling for the LIONS Marketing Preferences form available on /unsubscribe
    '#marketoForm .gs-marketo-form#mktoForm_1519': {
      '.mktoCheckboxList': {
        width: '20% !important',
        display: 'inline-block !important',
        position: 'relative',

        [mediaQueries.small]: {
          width: '50% !important',
        },

        'input[type=checkbox]': {
          display: 'inline-block',
          position: 'absolute',
          top: space.xsmall,
          left: 'auto',
          right: 0,

          [mediaQueries.small]: {
            left: 0,
            right: 'auto',
          },
        },
      },

      '.mktoLabel': {
        width: '80% !important',
        cursor: 'pointer',

        [mediaQueries.small]: {
          width: '24rem !important',
        },

        '& + .mktoGutter': {
          display: 'inline-block',
        },
      },
    },
  })
)
